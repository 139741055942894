<a class="btn btn-primary rounded-circle position-relative mx-2"
  style="width:40px;
        height: 40px;
        background-image: url('/assets/images/whatsapp.svg');
        background-size: 30px;
        background-position: center;
        background-repeat: no-repeat;">
  <!--i class="fa-brands fa-whatsapp fa-2x text-muted"></i-->
  <span class="position-absolute top-0 start-100 bg-danger translate-middle badge rounded-pill mt-1">+99 <span class="visually-hidden">unread messages</span></span>
</a>
